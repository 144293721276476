<template>
  <div id="environmentCard" @click="goDetails" style="position: relative;">
    
    <div class="title">
      <img :src="imgSrc" />
      <span>{{cardContent.name}}</span>
    </div>
    <div class="AO_InVal">
      <span>{{inVal}}</span>
    </div>
    <div class="switch">
      <a-switch :default-checked="state" :disabled="disabled" />
    </div>
    <div class="v-btn">
      <!-- <a-button  type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(1003)"/> -->
      <a-button v-if="cardContent.cameraId1>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId1)"/>
      <a-button v-if="cardContent.cameraId2>0" type="info" icon="video-camera" shape="round" size="small" @click.stop @click="getVideo(cardContent.cameraId2)"/>
    </div >
  </div>
</template>

<script>
import gas from "../../../../public/images/icon/092.png";
import wet from "../../../../public/images/icon/001.png";
import temp from "../../../../public/images/icon/007.png";
import windSpeed from "../../../../public/images/icon/427.png";
import windDiretion from "../../../../public/images/icon/433.png";
import noise from "../../../../public/images/icon/445.png";

export default {
  name: "environmentCard",
  data(){
    return {
      imgSrc: gas,
      disabled:true,
    }
  },
  props: {
    siteId:Number,    
    cardContent: {
      type: Object,
    },
  },
  created() {
    this.getImg();
  },
  methods: {
    goDetails() {
      console.log("goDetails cardContent",this.cardContent); 
      this.$router.push({
          path: "/homepage/environment/environmentDetails",
          query: {
            id:this.cardContent.id,
            siteId: this.siteId,
            instrumentName: this.cardContent.name,
            analogPoints: this.cardContent.aoMap.length>0?this.cardContent.aoMap[0].SignalPID:"",
            digitalPoints:this.cardContent.boMap.length>0?this.cardContent.boMap[0].SignalPID:"",
            deviceType:this.cardContent.type,
          },
      });
    },  
    getImg(){
      switch(this.cardContent.type){
        case 270:
          this.imgSrc = gas;
          break;
        case 420:
          this.imgSrc = wet;
          break;
        case 430:
          this.imgSrc = temp;
          break;
        case 650://风速
          this.imgSrc = windSpeed;
          break;
        case 660://风向
          this.imgSrc = windDiretion;
          break;
        case 670://噪声
          this.imgSrc = noise;
          break;
      }
    },  
    getVideo(cameraId) {
      const data = {
        vdialog: true,
        cameraId: cameraId,
        camera: {
          clientId: this.$store.getters.clientId,
          sitegrId: this.$store.getters.sitegrId,
          siteId: this.$route.query.id,
          cameraId: cameraId,
        }
      }
      console.log('getVideo>>>',data);
      this.$emit('hasVideoChange', data );
    },
  },
  computed:{    
    inVal:function(){
      if(this.cardContent.type==660){//风向
        if(this.cardContent.multiMap!=null&&this.cardContent.multiMap.length>0){
          return this.cardContent.multiMap[0].MO_Mode;
        }
      }
      else if(this.cardContent.aoMap!=null&&this.cardContent.aoMap.length>0){
        return this.cardContent.aoMap[0].AO_InVal;
      }else{
        return "";
      }
    },
    state:function(){
      if(this.cardContent.boMap==null||this.cardContent.boMap.length==0)
        return false;
      if(this.cardContent.boMap[0].BO_InVal==1){
        return true
      }else{
        return false
      }
    },
  },
  mounted() {
    // console.log("传参内容",this.cardContent);
  },
};
</script>

<style scoped>
#environmentCard {
  width: 200px;
  height: 150px;
  background-color: #f2f4f7;
  border-radius: 3px;
  margin: 0 auto 15px auto;
  border: 1px solid #ffffff;
  transition: 200ms;
}
#environmentCard:hover {
  border: 1px solid #7682ce;
}
.title{
  width: 100%;
  height: 40%;
  padding-left: 10px;
  font-size: 13px;
}
.AO_InVal{
  width: 100%;
  height: 40%;
  padding-left: 30%;
  padding-top: 10px;
  font-size: 24px;
}
.switch{
  width: 100%;
  height: 20%;
  padding-left: 10px;
}
.v-btn{
	  position: absolute;
	  right: 2%;
	  bottom: 1%;
	  display: grid;
	}
</style>